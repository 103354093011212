// Reset the default styles of a list so it becomes effectively unstyled.
// 1. On VoiceOver, any list without style type (`list-style: none`) or inlined
//    (`display: inline`) will *not* be voiced as a list.
//    Adding any content in the `::before` pseudo-element of the list element
//    (here a zero-width space) restores the original semantics.
//    If this CSS fix is not suitable for any reason, it can be overwritten and
//    solved from the DOM by adding `role="list"` to the list and
//    `role="listitem"` to its children.
//    See: https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/
export const resetList = {
  margin: 0,
  padding: 0,
  listStyle: 'none',

  '> ::before': {
    position: 'absolute',
    content: '"\\200B"' /* 1 */
  }
}

// Reset the default styles of a button so it becomes effectively unstyled.
// 1. Don‘t use shorthand because we want to be able to override the individual
//    padding values.
export const resetButton = {
  paddingTop: 0 /* 1 */,
  paddingRight: 0 /* 1 */,
  paddingBottom: 0 /* 1 */,
  paddingLeft: 0 /* 1 */,
  borderTopWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  backgroundColor: 'transparent',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  fontFamily: 'inherit'
}

// https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
export const visuallyHidden = {
  border: '0 !important',
  clip: 'rect(1px, 1px, 1px, 1px) !important',
  '-webkit-clip-path': 'inset(50%) !important',
  clipPath: 'inset(50%) !important',
  height: '1px !important',
  overflow: 'hidden !important',
  padding: '0 !important',
  position: 'absolute !important',
  width: '1px !important',
  whiteSpace: 'nowrap !important'
}
