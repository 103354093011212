import React, { useState } from 'react'
import styled from 'styled-components'

const Input = styled.input({
  padding: '5px 10px',
  marginBottom: '10px'
})
const Wrap = styled.div({
  border: '1px solid #ddd',
  padding: '20px 20px 10px 20px'
})

const listItems = ['foo', 'bar', 'baz', 'quix']

const FilterableList = () => {
  const [searchResults, setSearchResults] = useState(listItems)

  const handleChange = event => {
    const value = event.target.value.toLowerCase()

    if (value) {
      const filteredItems = listItems.filter(item =>
        item?.toLowerCase().includes(value)
      )

      setSearchResults(
        filteredItems.length > 0 || value ? filteredItems : listItems
      )
    } else {
      setSearchResults(listItems)
    }
  }

  return (
    <Wrap>
      <Input
        aria-controls="tag-list"
        onChange={handleChange}
        placeholder="Search"
        type="text"
      />
      <ul aria-live="polite" id="tag-list" role="region">
        {searchResults.length < 1 ? (
          <li>No results</li>
        ) : (
          searchResults.map(item => <li key={item}>{item}</li>)
        )}
      </ul>
    </Wrap>
  )
}

export default FilterableList
