/* eslint-disable react/prop-types */
import React from 'react'

export const ListItem = props => {
  return (
    <li style={{ color: props.index % 2 === 0 ? 'red' : 'blue' }}>
      {props.children} {props.index}
    </li>
  )
}

const List = props => {
  return <ul>{props.children}</ul>
}

const BasicList = () => {
  return (
    <List>
      {['foo', 'bar', 'baz'].map((item, index) => (
        <ListItem key={item} index={index}>
          {item}
        </ListItem>
      ))}
    </List>
  )
}

export default BasicList
