import { ClubsIcon, DiamondsIcon, HeartsIcon, SpadesIcon } from "../../../../contents/posts/getting-values-in-javascript/Icons";
import { CardWrap, PlayingCard } from "../../../../contents/posts/getting-values-in-javascript/styles";
import { suiteIcons, getSuiteWithIf, getSuiteWithTernary, getSuitWithCase, getSuitWithIfElse } from "../../../../contents/posts/getting-values-in-javascript/helpers";
import * as React from 'react';
export default {
  ClubsIcon,
  DiamondsIcon,
  HeartsIcon,
  SpadesIcon,
  CardWrap,
  PlayingCard,
  suiteIcons,
  getSuiteWithIf,
  getSuiteWithTernary,
  getSuitWithCase,
  getSuitWithIfElse,
  React
};