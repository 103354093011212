import React from 'react'
import PropTypes from 'prop-types'
import Algolia from '../../icons/Algolia'
import ArrowRight from '../../icons/ArrowRight'
import Book from '../../icons/Book'
import Cross from '../../icons/Cross'
import Do from '../../icons/Do'
import Nc from '../../icons/Nc'
import Info from '../../icons/Info'
import CheckMark from '../../icons/CheckMark'
import Spinner from '../../icons/Spinner'
import { Label, Wrap } from './styles'

const icons = {
  algolia: Algolia,
  arrowRight: ArrowRight,
  book: Book,
  checkMark: CheckMark,
  cross: Cross,
  digitalOcean: Do,
  info: Info,
  nameCheap: Nc,
  spinner: Spinner
}

const sizes = {
  small: 16,
  medium: 24,
  large: 32,
  extraLarge: 40
}

const colors = {
  red: '--red',
  green: '--green',
  gray: '--gray-dark',
  black: '--black'
}

const Icon = props => {
  const { className, color, isBlock, label, role, size } = props
  const IconComponent = icons[props.icon] || null

  if (!IconComponent) return null

  return (
    <Wrap
      className={className}
      color={colors[color]}
      size={sizes[size]}
      isBlock={isBlock}
    >
      <IconComponent
        aria-hidden
        fill="currentcolor"
        focusable="false"
        height={sizes[size]}
        role={role}
        title={label}
        width={sizes[size]}
      />
      {label && <Label>{label}</Label>}
    </Wrap>
  )
}

Icon.defaultProps = {
  color: 'black',
  isBlock: true,
  size: 'small'
}

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(colors)),
  icon: PropTypes.oneOf(Object.keys(icons)),
  isBlock: PropTypes.bool,
  label: PropTypes.string,
  role: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes))
}

export default Icon
