import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrap = styled.div({
  marginBottom: '20px'
})

const TabList = styled.ul({
  display: 'flex',
  gap: '1px',
  listStyleType: 'none',
  margin: '0 0 1px'
})

const TabItem = styled.li({
  flex: '1 0 auto',
  margin: 0
})

// 1. Stretch the link to fill its parent
const Tab = styled.a(props => ({
  backgroundColor: props.isActive
    ? 'var(--gray-light)'
    : 'var(--gray-lightest)',
  display: 'flex',
  flexGrow: 1 /* 1 */,
  padding: '10px 15px',
  textTransform: 'uppercase'
}))

const TabPanel = styled.div(props => ({
  backgroundColor: 'var(--gray-light)',
  marginBottom: '1px',
  minHeight: '400px',
  padding: '15px',
  display: !props.isActive ? 'none' : undefined
}))

const Tabs = ({ tabs = [], id }) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0])

  // Store the tab element references here
  const tabRefs = React.useRef({})
  const handleClick = tab => event => {
    event.preventDefault()
    setActiveTab(tab)
  }
  const getId = (...rest) => [...rest, id].join('-')

  const focusTab = tab => {
    setActiveTab(tab)
    // Get the reference from the `tabRefs` and actual move focus to the tab
    tabRefs.current[tab].focus()
  }

  const handleKeyboard = tab => event => {
    const tabCount = tabs.length - 1
    const currentTabIndex = tabs.indexOf(tab)
    const firstTab = tabs[0]
    const lastTab = tabs[tabCount]
    const nextTab = tabs[currentTabIndex + 1]
    const prevTab = tabs[currentTabIndex - 1]

    if (event.key === 'ArrowRight') {
      if (tabCount > currentTabIndex) return focusTab(nextTab)
      return focusTab(firstTab)
    }

    if (event.key === 'ArrowLeft') {
      if (currentTabIndex > 0) return focusTab(prevTab)
      return focusTab(lastTab)
    }

    if (event.key === 'Home') {
      event.preventDefault()
      return focusTab(firstTab)
    }

    if (event.key === 'End') {
      event.preventDefault()
      return focusTab(lastTab)
    }
  }

  return (
    <Wrap>
      <TabList role="tablist">
        {tabs.map(tab => {
          const isActive = activeTab === tab

          return (
            <TabItem key={tab}>
              <Tab
                aria-selected={isActive}
                href={'#' + getId(tab, 'tabpanel')}
                id={getId(tab)}
                isActive={isActive}
                onClick={handleClick(tab)}
                onKeyDown={handleKeyboard(tab)}
                tabIndex={isActive ? 0 : -1}
                ref={ref => (tabRefs.current[tab] = ref)}
                role="tab"
              >
                {tab}
              </Tab>
            </TabItem>
          )
        })}
      </TabList>
      {tabs.map(tab => (
        <TabPanel
          aria-labelledby={getId(tab)}
          id={getId(tab, 'tabpanel')}
          isActive={activeTab === tab}
          key={tab}
          tabIndex={0}
          role="tabpanel"
        >
          Content of <b>{tab}</b> with a <a href="/tabs">focusable</a> element.
        </TabPanel>
      ))}
    </Wrap>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired
}

export default Tabs
