import angleQuoteList from "../../../../contents/posts/lists/angle-quote-list.html";
import blackCircleList from "../../../../contents/posts/lists/black-circle-list.html";
import blackCircleListColor from "../../../../contents/posts/lists/black-circle-list-color.html";
import checkMarkList from "../../../../contents/posts/lists/check-mark-list.html";
import arrowList from "../../../../contents/posts/lists/arrow-list.html";
import hyphenList from "../../../../contents/posts/lists/hyphen-list.html";
import markerList from "../../../../contents/posts/lists/marker-list.html";
import * as React from 'react';
export default {
  angleQuoteList,
  blackCircleList,
  blackCircleListColor,
  checkMarkList,
  arrowList,
  hyphenList,
  markerList,
  React
};