import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TabNav = styled.ul({
  display: 'flex',
  gap: '1px',
  listStyleType: 'none',
  margin: '0 0 1px'
})

const TabItem = styled.li({
  flex: '1 0 auto',
  margin: 0
})

// 1. Stretch the link to fill its parent
const TabLink = styled.a(props => ({
  backgroundColor: props.isActive
    ? 'var(--gray-light)'
    : 'var(--gray-lightest)',
  display: 'flex',
  flexGrow: 1 /* 1 */,
  padding: '10px 15px',
  textTransform: 'uppercase'
}))

const Tab = styled.div(props => ({
  backgroundColor: 'var(--gray-light)',
  marginBottom: '1px',
  minHeight: '400px',
  padding: '15px',
  display: !props.isActive ? 'none' : undefined
}))

const Tabs = ({ tabs = [], id }) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0])

  const handleClick = tab => event => {
    event.preventDefault()
    setActiveTab(tab)
  }
  const getId = (...rest) => [...rest, id].join('-')

  return (
    <div>
      <TabNav>
        {tabs.map(tab => (
          <TabItem key={tab}>
            <TabLink
              href={'#' + getId(tab, 'tabpanel')}
              id={getId(tab)}
              isActive={activeTab === tab}
              onClick={handleClick(tab)}
            >
              {tab}
            </TabLink>
          </TabItem>
        ))}
      </TabNav>
      {tabs.map(tab => (
        <Tab id={getId(tab, 'tabpanel')} key={tab} isActive={activeTab === tab}>
          {tab} tab’s content
        </Tab>
      ))}
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired
}

export default Tabs
