import styled from 'styled-components'

// Hide content while keeping it accessible for users of assistive technologies.
// See: http://hugogiraudel.com/2016/10/13/css-hide-and-seek/
// See: https://github.com/twbs/bootstrap/pull/25197
const VisuallyHidden = styled.span({
  border: '0 !important',
  clip: 'rect(1px, 1px, 1px, 1px) !important',
  height: '1px !important',
  overflow: 'hidden !important',
  paddingBottom: '0 !important',
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  paddingTop: '0 !important',
  position: 'absolute !important',
  whiteSpace: 'nowrap !important',
  width: '1px !important'
})

export default VisuallyHidden
