import React from 'react'

export const ClubsIcon = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M12.294 6.137c-.922 0-1.751.384-2.341 1.011-.25.265-.684.58-1.153.856.22-.842.917-1.902 1.4-2.367a3.27 3.27 0 001-2.367C11.2 1.475 9.771.018 8-.001 6.229.018 4.8 1.474 4.8 3.27c0 .932.38 1.771 1 2.367.484.465 1.18 1.525 1.4 2.367-.469-.277-.903-.591-1.153-.856a3.197 3.197 0 00-2.341-1.011C1.919 6.137.47 7.6.47 9.408s1.448 3.271 3.236 3.271c.923 0 1.751-.396 2.341-1.023.263-.279.726-.627 1.223-.916-.047 2.308-1.149 4.003-2.271 4.67V16h6v-.59c-1.122-.668-2.224-2.363-2.271-4.67.498.289.961.637 1.223.916a3.21 3.21 0 002.341 1.023c1.787 0 3.236-1.464 3.236-3.271s-1.448-3.271-3.236-3.271z" />
  </svg>
)

export const DiamondsIcon = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M8 0L3 8l5 8 5-8z" />
  </svg>
)

export const HeartsIcon = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M11.8 1c-1.682 0-3.129 1.368-3.799 2.797C7.33 2.368 5.883 1 4.201 1a4.202 4.202 0 00-4.2 4.2c0 4.716 4.758 5.953 8 10.616 3.065-4.634 8-6.05 8-10.616 0-2.319-1.882-4.2-4.2-4.2z" />
  </svg>
)

export const SpadesIcon = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M12.775 5.44C9.751 3.192 8.708 1.393 8.001 0c-.708 1.393-1.75 3.192-4.774 5.44-5.157 3.833-.303 9.182 3.965 6.238-.278 1.827-1.227 3.159-2.191 3.733v.59h6v-.59c-.964-.574-1.913-1.906-2.191-3.733 4.268 2.944 9.122-2.405 3.965-6.238z" />
  </svg>
)
