import ArrayTable from "../../../../contents/posts/array-cheat-sheet/ArrayTable";
import { Col } from "../../../../contents/fragments/Alfred/styles";
import alfredSearchImg2 from "../../../../contents/posts/array-cheat-sheet/alfred-tmux-search.jpg";
import alfredSettingsImg from "../../../../contents/fragments/Alfred/alfred-custom-tmux-search-settings.png";
import * as React from 'react';
export default {
  ArrayTable,
  Col,
  alfredSearchImg2,
  alfredSettingsImg,
  React
};