import React from 'react'
import styled from 'styled-components'

const ResizableContainer = styled.div(props => ({
  resize: props.mode,
  overflow: 'auto', // Something other than visible
  height: '400px',
  marginBottom: '20px'
}))

const Wrap = styled.div({
  display: 'grid',
  height: '100%',
  gridTemplate: 'auto 1fr auto / auto 1fr auto'
})

const Header = styled.header({
  backgroundColor: '#FCB5AC',
  padding: '1rem',
  gridColumn: '1 / 4'
})

const SidebarLeft = styled.aside({
  backgroundColor: '#B5E5CF',
  padding: '1rem',
  gridColumn: '1 / 2'
})

const SidebarRight = styled.aside({
  backgroundColor: '#B5E5CF',
  padding: '1rem',
  gridColumn: '3 / 4'
})

const Footer = styled.footer({
  backgroundColor: '#FCB5AC',
  padding: '1rem',
  gridColumn: '1 / 4'
})

const Content = styled.section({
  backgroundColor: '#FAE8E0',
  gridColumn: '2 / 3',
  padding: '1rem'
})

const HolyGrail = () => {
  const [mode, setMode] = React.useState('both')

  const handleChange = event => {
    setMode(event.target.value)
  }

  return (
    <ResizableContainer mode={mode}>
      <Wrap>
        <Header>Resizable wrapper</Header>
        <SidebarLeft>Sidebar content</SidebarLeft>
        <Content>
          <p>Choose the resizing behavior:</p>
          <select onChange={handleChange}>
            <option value="both">both</option>
            <option value="horizontal">horizontal</option>
            <option value="vertical">vertical</option>
            <option value="block">block</option>
            <option value="inline">inline</option>
            <option value="none">none</option>
          </select>
          <br />
          <small>Test in FireFox for full effect</small>
        </Content>
        <SidebarRight>Sidebar content</SidebarRight>
        <Footer>Holy Grail</Footer>
      </Wrap>
    </ResizableContainer>
  )
}

export default HolyGrail
