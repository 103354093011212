import styled from 'styled-components'
import { visuallyHidden } from '../../utils/style-utils'

export const Label = styled.span({
  ...visuallyHidden
})

export const Wrap = styled.span(props => ({
  color: `var(${props.color})`,
  display: props.isBlock ? 'block' : 'inline-block',
  height: props.size + 'px',
  width: props.size + 'px',
  lineHeight: 0
}))
