import styled from 'styled-components'

export const Pre = styled.pre({
  margin: 0
})

// 1. Needs to be an inline block or padding is not applied.
export const Code = styled.code({
  padding: '0 20px 0 0',
  display: 'inline-block' /* 1 */
})

export const CodeTd = styled.td({
  paddingRight: 0,
  position: 'relative',
  maxWidth: '200px',
  overflow: 'scroll'
})
