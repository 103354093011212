import styled from 'styled-components'

export const PlayingCard = styled.div({
  alignItems: 'center',
  borderRadius: '5px',
  boxShadow: '0 0 5px gray',
  display: 'flex',
  height: '150px',
  justifyContent: 'center',
  marginBottom: '20px',
  marginRight: '20px',
  width: '100px'
})

export const CardWrap = styled.div({
  display: 'flex'
})
