/* eslint-disable react/prop-types */
import React from 'react'

const Foo = props => <div>Foo: {props.index}</div>
const Bar = props => <div>Bar: {props.index}</div>

const Wrap = props => {
  let fooCount = 0
  let barCount = 0
  const children = React.Children.map(props.children, child => {
    if (child.type === Foo) {
      return React.cloneElement(child, { index: fooCount++ })
    }

    return React.cloneElement(child, { index: barCount++ })
  })

  return children
}

const Matcher = () => {
  return (
    <Wrap>
      <Foo />
      <Foo />
      <Foo />
      <Bar />
      <Bar />
    </Wrap>
  )
}

export default Matcher
