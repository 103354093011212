import React from 'react'
import PropTypes from 'prop-types'
import reactStringReplace from 'react-string-replace'
import Input from '@components/Input'
import { Caption, Form, Table, Th, Wrap } from '../tmux-cheat-sheet/styles'
import arrayMethods from './arrayMethods'
import { CodeTd, Code, Pre } from './styles'

const baseUrl =
  'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/'

const Link = ({ slug, children }) => {
  return slug ? <a href={baseUrl + slug}>{children}</a> : children
}

Link.propTypes = {
  children: PropTypes.node,
  slug: PropTypes.string
}

const ArrayTable = () => {
  const [searchResults, setSearchResults] = React.useState(arrayMethods)
  const [searchValue, setSearchValue] = React.useState('')
  const fieldRef = React.useRef(null)

  const doSearch = React.useCallback(
    value => {
      setSearchValue(value)

      // If no value, then just use the raw data
      if (!value) return setSearchResults(arrayMethods)

      const filteredTableData = arrayMethods.map(table => {
        const items = table.items
          // Do the actual search
          .filter(item => {
            // This regex has to be defined here and not earlier
            const reg = new RegExp(value, 'gi')

            return reg.test(item.method) || reg.test(item.description)
          })
          .map(item => {
            // Highlight the search results by wrapping them to `<mark>`
            const description = reactStringReplace(
              item.description,
              value,
              (match, index) => <mark key={index}>{match}</mark>
            )
            const method = reactStringReplace(
              item.method,
              value,
              (match, index) => <mark key={index}>{match}</mark>
            )

            return { ...item, method, description }
          })

        return { ...table, items }
      })

      const hasResults = filteredTableData.some(data => data.items.length > 0)

      setSearchResults(hasResults || value ? filteredTableData : arrayMethods)
    },
    [setSearchResults]
  )

  React.useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search)
      const searchTerm = params.get('s')
      doSearch(searchTerm)
      fieldRef.current.value = searchTerm
    }
  }, [doSearch])

  // This updates the URL of the page when enter is hit in the search form
  const handleSubmit = event => {
    event.preventDefault()
    const url = new URL(window.location.href)
    url.searchParams.set('s', searchValue)
    window.location.href = url.href
  }
  const hasResults = searchResults.some(rows => rows.items.length > 0)
  const handleChange = event => {
    doSearch(event.target.value)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          type="search"
          placeholder="Search"
          aria-label="search"
          onChange={handleChange}
          ref={fieldRef}
        />
      </Form>
      <Wrap>
        {!hasResults
          ? 'No results'
          : searchResults.map(rows => {
              const hasRows = rows.items.length > 0

              return (
                <Table aria-hidden={!hasRows} key={rows.name} isDim={!hasRows}>
                  <Caption>{rows.name}</Caption>
                  <thead>
                    <tr>
                      <Th>Command</Th>
                      <Th>Description</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {!hasRows ? (
                      <tr>
                        <td>No results</td>
                      </tr>
                    ) : (
                      rows.items.map((item, index) => (
                        <tr key={index}>
                          <React.Fragment key={item.description}>
                            <td>
                              <Link slug={item.slug}>
                                <code>{item.method}</code>
                              </Link>
                              {item.isExperimental && ' ⚠️'}
                            </td>
                            <td>{item.description}</td>
                            <CodeTd>
                              <Pre>
                                <Code>{(item.example || []).join('\n')}</Code>
                              </Pre>
                            </CodeTd>
                          </React.Fragment>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )
            })}
      </Wrap>
    </>
  )
}

export default ArrayTable
