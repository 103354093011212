const arrayMethods = [
  {
    name: 'Adding',
    description: 'Array methods that add elements to array.',
    items: [
      {
        method: '.push()',
        slug: 'push',
        description: 'Add anything to the end of an array.',
        example: [
          'let a = []',
          'let b = {}',
          'a.push(b)',
          'console.log(a[0] === b) // true'
        ]
      },
      {
        method: '.push()',
        description: 'Add more than one item at a time.',
        slug: 'push',
        example: [
          "let x = ['a']",
          "x.push('b', 'c')",
          "console.log(x) // ['a', 'b', 'c']"
        ]
      },
      {
        method: '.unshift()',
        slug: 'unshift',
        description:
          'Add to the beginning of array and returns the new length of the array.',
        example: [
          "let x = ['c', 'd']",
          "x.unshift('a', 'b') // 4",
          "console.log(x) // ['a', 'b', 'c', 'd']"
        ]
      },
      {
        method: '.splice()',
        slug: 'splice',
        description: 'Add element to an arbitrary location (second param 0).',
        example: [
          'let a = [1, 2, 3, 4]',
          "a.splice(2, 0, 'foo')",
          'console.log(a) // [1, 2, "foo", 3, 4]'
        ]
      }
    ]
  },
  {
    name: 'Reorganizing',
    description: 'Methods that somehow mutate the array.',
    items: [
      {
        method: '.copyWithin()',
        slug: 'copyWithin',
        description:
          'Copy part of an array to another location within the same array.',
        example: [
          "let a = ['a', 'b', 'c', 'd', 'e']",
          '// target, start, end',
          'array1.copyWithin(0, 3, 4)',
          'console.log(a) // ["d", "b", "c", "d", "e"]'
        ]
      },
      {
        method: '.slice()',
        slug: 'slice',
        description:
          'Returns a portion of an array selected with the start and end parameters.',
        example: [
          "let a = ['ant', 'bison', 'camel', 'duck', 'elephant']",
          'console.log(animals.slice(2)) // ["camel", "duck", "elephant"]',
          'console.log(animals.slice(2, 4)) // ["camel", "duck"]'
        ]
      },
      {
        method: '.splice()',
        slug: 'splice',
        description: 'Replace an arbitrary element in array (second param 1).',
        example: [
          'let a = [1, 2, 3, 4]',
          "a.splice(2, 1, 'foo')",
          'console.log(a) // [1, 2, "foo", 4]'
        ]
      }
    ]
  },
  {
    name: 'Combining',
    description: 'Methods that can combine arrays in some way.',
    items: [
      {
        method: '.concat()',
        slug: 'concat',
        description: 'Create a new array from two arrays.',
        example: [
          "let x = ['a', 'b', 'c']",
          "let y = ['d', 'e', 'f']",
          'let z = x.concat(y)',
          "console.log(z) // ['a', 'b', 'c', 'd', 'e', 'f']"
        ]
      },
      {
        method: '...',
        description:
          'Not a method, but create a new array from two arrays by spreading them.',
        example: [
          "let x = ['a', 'b']",
          "let y = ['c', 'd']",
          'let z = [...x, ...y]',
          "console.log(z) // ['a', 'b', 'c', 'd']"
        ]
      }
    ]
  },
  {
    name: 'Removing',
    description: 'Methods that remove elements from an array.',
    items: [
      {
        method: '.pop()',
        slug: 'pop',
        description:
          'Removes the last element from array and returns it. Changes the length of the array.',
        example: [
          "let a = ['a', 'b', 'c']",
          'console.log(a.pop()) // c',
          'console.log(a) // ["a", "b"]'
        ]
      },
      {
        method: '.shift()',
        slug: 'shift',
        description:
          'Like pop but removes the first element from array and returns it. Also changes the length of the array.',
        example: [
          "let a = ['a', 'b', 'c']",
          'console.log(a.shift()) // a',
          'console.log(a) // ["b", "c"]'
        ]
      },
      {
        method: '.splice()',
        slug: 'splice',
        description:
          'Remove elements from the middle of an array. Param 1: index to start removing. Param 2: index to stop removing.',
        example: [
          "let a = ['a', 'b', 'c', 'd', 'e']",
          'a.splice(1, 3)',
          'console.log(a) // ["a", "e"]'
        ]
      }
    ]
  },
  {
    name: 'Filtering/Searching',
    description:
      'Methods that can find elements or filter out elements from an array.',
    items: [
      {
        method: '.filter()',
        slug: 'filter',
        description:
          'Keeps the items in the array that pass the test provided by the callback function.',
        example: [
          "let a = ['foo', 'bar', 'fooz']",
          "let b = a.filter(v => v.startsWith('foo'))",
          "console.log(b) // ['foo', 'fooz']"
        ]
      },
      {
        method: '.find()',
        slug: 'find',
        description: 'Finds the first item in an array that matches.',
        example: [
          'let a = [5, 12, 8, 130, 44]',
          'let b = a.find(v => v > 10)',
          'console.log(b) // 12'
        ]
      },
      {
        method: '.findIndex()',
        slug: 'findIndex',
        description:
          'Like find but instead of returning the item, it returns the index.',
        example: [
          'let a = [5, 12, 8, 130, 44]',
          'let b = a.findIndex(v => v > 13)',
          'console.log(b) // 3'
        ]
      },
      {
        method: '.indexOf()',
        slug: 'indexOf',
        description:
          'Finds the first index of an element. Returns -1 if not found.',
        example: [
          "let a = ['foo', 'bar', 'baz']",
          "console.log(a.indexOf('baz')) // 2",
          "console.log(a.indexOf('quix')) // -1"
        ]
      },
      {
        method: '.lastIndexOf()',
        slug: 'lastIndexOf',
        description:
          'Like indexOf, but finds the last index of an element. Returns -1 if not found.',
        example: [
          "let a = ['foo', 'bar', 'baz', 'foo']",
          "console.log(a.lastIndexOf('foo')) // 3",
          "console.log(a.lastIndexOf('quix')) // -1"
        ]
      }
    ]
  },
  {
    name: 'Sorting',
    description: 'Methods that can change array order by sorting them.',
    items: [
      {
        method: '.sort()',
        slug: 'sort',
        description: 'Sort alphabetically.',
        example: [
          "let a = ['d', 'j', 'a', 'b', 'c', 'g']",
          'a.sort()',
          'console.log(a) // ["a", "b", "c", "d", "g", "j"]'
        ]
      },
      {
        method: '.sort()',
        slug: 'sort',
        description: 'Sort in reversed alphabetical order.',
        example: [
          "let a = ['d', 'j', 'a', 'b', 'c', 'g']",
          'a.sort().reverse()',
          'console.log(a) // [ "j", "g", "d", "c", "b", "a"]'
        ]
      },
      {
        method: '.sort()',
        slug: 'sort',
        description: 'Sort numerically.',
        example: [
          'let a = [5, 10, 7, 1, 3, 2]',
          'a.sort((a, b) => a - b)',
          'console.log(a) // [ 1, 2, 3, 5, 7, 10]'
        ]
      },
      {
        method: '.sort()',
        slug: 'sort',
        description: 'Descending numerical sort (flip the `a` anb `b` around).',
        example: [
          'let a = [5, 10, 7, 1, 3, 2]',
          'a.sort((a, b) => b - a)',
          'console.log(a) // [10, 7, 5 ,3, 2, 1]'
        ]
      }
    ]
  },
  {
    name: 'Loop structures',
    description:
      'Methods that iterate every item in the array. All array methods are kind of iterative tho, but you know what I mean.',
    items: [
      {
        method: '.map()',
        slug: 'map',
        description:
          'Maps an array to another array by executing a callback function on each element.',
        example: [
          'let a = [1, 2, 3, 4]',
          'let b = a.map(v => v * 2)',
          'console.log(b) // 2, 4, 6, 8'
        ]
      },
      {
        method: '.flatMap()',
        slug: 'flatMap',
        description:
          'Same as map but it flattens the array, same as: [[]].map(v => v).flat().',
        example: [
          'let a = [1, 2, 3, 4]',
          'let b = a.flatMap(v => [v * 2])',
          'console.log() // 2, 4, 6, 8'
        ]
      },
      {
        method: '.forEach()',
        slug: 'forEach',
        description:
          'Executes a function for every element in array, does not return anything.',
        example: [
          'let a = [1, 2]',
          'a.forEach(x => console.log(x))',
          '// 1',
          '// 2'
        ]
      },
      {
        method: '.reduce()',
        slug: 'reduce',
        description:
          'Executes a user-supplied “reducer” callback function on each element of the array, in order, passing in the return value from the calculation on the preceding element.',
        example: [
          'let a = [1, 2, 3, 4]',
          'let b = a.reduce((acc, v) => acc + v)',
          '// Basically: 1 + 2 + 3 + 4',
          'console.log(b) // 10'
        ]
      },
      {
        method: '.reduce()',
        slug: 'reduce',
        description: 'Return object from an array method.',
        example: [
          'let a = [1, 2, 3, 4]',
          'let b = a.reduce((acc, v) => ({ [v]: v, ...acc }), {})',
          'console.log(b) // {"1": 1,"2": 2,"3": 3,"4": 4}'
        ]
      },
      {
        method: '.reduceRight()',
        slug: 'reduceRight',
        description: 'Like reduce but reads the array from right to left.',
        example: [
          'let a = [1, 2, 3, 4]',
          'let b = a.reduce((acc, v) => acc + v)',
          '// Basically: 1 + 2 + 3 + 4',
          'console.log(b) // 10'
        ]
      }
    ]
  },
  {
    name: 'Booleans',
    description: 'Methods that return boolean values.',
    items: [
      {
        method: '.every()',
        slug: 'every',
        description: 'Tests if every item in the array passes the test.',
        example: [
          'let isBelow = v => v < 40',
          'let a = [1, 30, 39, 29, 10, 13]',
          'console.log(a.every(isBelow)) // true'
        ]
      },
      {
        method: '.some()',
        slug: 'some',
        description: 'Tests if some items in the array passes the test.',
        example: [
          'let isOver = v => v > 40',
          'let a = [1, 30, 41, 29, 10, 13]',
          'console.log(a.some(isOver)) // true'
        ]
      },
      {
        method: '.includes()',
        slug: 'includes',
        description: 'Test if an array contains a value.',
        example: [
          'let a = [1, 2, 3]',
          'let b = a.includes(2)',
          'console.log(b) // true'
        ]
      }
    ]
  },
  {
    name: 'Creating',
    description: 'Methods that create new arrays.',
    items: [
      {
        method: 'Array.of()',
        slug: 'of',
        description: 'Creates a new array from the given arguments.',
        example: ["Array.of('foo', 'bar')", "// ['foo', 'bar']"]
      },
      {
        method: 'Array.from()',
        slug: 'from',
        description:
          'Creates a new array from an array-like or iterable object.',
        example: [
          "console.log(Array.from('foo')) // ['f', 'o', 'o']",
          'console.log(Array.from([1, 2, 3], x => x + x)) // [2, 4, 6]'
        ]
      },
      {
        method: '.fill()',
        slug: 'fill',
        description: 'Change all elements in an array to a static value.',
        example: [
          'let a = [1, 2, 3, 4]',
          'let b = a.fill(0, 2, 4)',
          'console.log(b) // [1, 2, 0, 0]'
        ]
      }
    ]
  },
  {
    name: 'Morphing',
    description: 'These methods change the array to another data type.',
    items: [
      {
        method: '.entries()',
        slug: 'entries',
        link: '/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/entries',
        description:
          'Returns an iterator object with key/value pairs for each index in the array.',
        example: [
          "let a = ['a', 'b', 'c']",
          'let iterator = a.entries()',
          'console.log(iterator.next().value) // [0, "a"]',
          'console.log([...iterator]) // [[0, "a"], [1, "b"], [2, "c"]]`]'
        ]
      },
      {
        method: '.values()',
        slug: 'values',
        description:
          'Returns an iterator object with values for each index in the array. Changes the data type from array to iterator.',
        example: [
          "let a = ['a', 'b', 'c']",
          'let iterator = a.values()',
          'for (const v of iterator) { console.log(v) } // a, b, c'
        ]
      },
      {
        method: '.keys()',
        slug: 'keys',
        description:
          'Returns an iterator object with keys for each index in the array. Changes the data type from array to iterator.',
        example: [
          "let a = ['a', 'b', 'c']",
          'let iterator = a.keys()',
          'for (const v of iterator) { console.log(v) } // 1, 2, 3'
        ]
      },
      {
        method: '.join()',
        slug: 'join',
        description:
          'Turn array into a string by joining the element together.',
        example: [
          "let a = ['foo', 'bar', 'baz']",
          'a.join() // foo,bar,baz',
          "a.join('-') // foo-bar-baz"
        ]
      },
      {
        method: '.toString()',
        slug: 'toString',
        description: 'Turn array or any other object into a string.',
        example: ["let a = ['foo', 'bar', 'baz']", 'a.join() // foo,bar,baz']
      },
      {
        method: '.toLocaleString()',
        slug: 'toLocaleString',
        description: 'Stringifies an array in a locale sensitive way.',
        link: '/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toLocaleString',
        example: [
          "let a = [1, 'a', new Date('21 Dec 1997 14:12:00 UTC')]",
          "a.toLocaleString('en', { timeZone: 'UTC' }) // 1,a,12/21/1997, 2:12:00 PM",
          "a.toLocaleString('de') // '1,a,21.12.1997, 15:12:00'"
        ]
      }
    ]
  },
  {
    name: 'Information',
    description: 'These methods return information about an array.',
    items: [
      {
        method: '.at()',
        slug: 'at',
        isExperimental: true,
        description:
          'Takes in integer value and returns the array element at that index. -1 returns the last element. ⚠️ Experimental technology.',
        example: [
          "let x = ['a', 'b', 'c']",
          'console.log(a.at(1)) // b',
          'console.log(a.at(-1)) // c'
        ]
      },
      {
        method: '.length',
        slug: 'length',
        description: 'Gets the element count of an array.',
        example: ["let x = ['a', 'b', 'c']", 'console.log(a.length) // 3']
      },
      {
        method: 'Array.isArray()',
        slug: 'isArray',
        description: 'Tests if a given variable is an array.',
        example: ["let a = ['a', 'b']", 'console.log(Array.isArray(a)) // true']
      }
    ]
  },
  {
    name: 'Misc.',
    description: 'Array methods that I couldn’t figure out where to put :)',
    items: [
      {
        method: '.flat()',
        slug: 'flat',
        description: 'Flatten a nested array.',
        example: [
          'let a = [0, 1, 2, [3, 4]]',
          'let b = a.flat()',
          'console.log(b) // [0, 1, 2, 3, 4]'
        ]
      },
      {
        method: '.reverse()',
        slug: 'reverse',
        description: 'Reverses the order of an array.',
        example: [
          'let a = [1, 2, 3, 4]',
          'a.reverse()',
          'console.log(a) // [4, 3, 2, 1]'
        ]
      }
    ]
  }
]

export default arrayMethods
