import React from 'react'
import PropTypes from 'prop-types'
import VisuallyHidden from '@components/VisuallyHidden'
import useSortableData from '../../../src/hooks/useSortableData'
import { BlankButton } from './styles'

const randomThings = [
  { name: 'Sock', description: 'Clothing item' },
  { name: 'Tube', description: 'Topological shape' },
  { name: 'Chair', description: 'Sitting device' },
  { name: 'Aluminum siding', description: 'Building material' },
  { name: 'Cleanex', description: 'Tissue' },
  { name: 'Jade buddha', description: 'Trinket' },
  { name: 'Knob', description: 'Adjective' },
  { name: 'Tangerine', description: 'Fruit' }
]

const SortButton = ({ direction, id, onClick, sortBy }) => {
  const arrows = { ascending: '↓', descending: '↑' }
  const arrow = sortBy === id ? arrows[direction] : '↕︎'

  return (
    <BlankButton id={id} onClick={onClick}>
      {arrow}
      <VisuallyHidden>Sort {direction}</VisuallyHidden>
    </BlankButton>
  )
}

SortButton.propTypes = {
  direction: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  sortBy: PropTypes.string
}

const SortableTableWithHooks = props => {
  const { items, requestSort, sortConfig } = useSortableData(
    randomThings,
    props.config
  )

  return (
    <table>
      <caption align="bottom">List of random things</caption>
      <thead>
        <tr>
          <th>
            Name{' '}
            <SortButton
              direction={sortConfig?.direction}
              id="name"
              onClick={() => requestSort('name')}
              sortBy={sortConfig?.key}
            />
          </th>
          <th>
            Description{' '}
            <SortButton
              direction={sortConfig?.direction}
              id="description"
              onClick={() => requestSort('description')}
              sortBy={sortConfig?.key}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(thing => (
          <tr key={thing.name}>
            <td>{thing.name}</td>
            <td>{thing.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

SortableTableWithHooks.propTypes = {
  config: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(['ascending', 'descending'])
  })
}

export default SortableTableWithHooks
