/**
 * This helper matches all text between two special comments: `// start-block`
 * and `// end-block` and returns it as is.
 *
 * @param {string} string - The processed string
 * @returns {string}
 */
const trimmer = (string = '') => {
  return string.match(/(?<=\/\/ ?start-block\n)[\s\S]*(?=\/\/ ?end-block)/)?.[0]
}

export default trimmer
