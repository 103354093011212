/* eslint-disable react/prop-types */
import React from 'react'

const Foo = props => <div>Foo: {props.index}</div>

Foo.defaultProps = {
  __TYPE: 'Foo'
}

const Bar = props => <div>Bar: {props.index}</div>

Bar.defaultProps = {
  __TYPE: 'Bar'
}

const Wrap = props => {
  let fooCount = 0
  let barCount = 0
  const children = React.Children.map(props.children, child => {
    if (child.props.__TYPE === 'Foo') {
      return React.cloneElement(child, { index: fooCount++ })
    }

    return React.cloneElement(child, { index: barCount++ })
  })

  return children
}

const HiddenProps = props => {
  return (
    <Wrap>
      <Foo />
      <Foo />
      <Foo />
      <Bar />
      <Bar />
    </Wrap>
  )
}

export default HiddenProps
