import React from 'react'
import propTypes from 'prop-types'

const FilterDemo = props => {
  if (props.filterType === 'mutative') {
    const tableData = [
      {
        title: 'Apples',
        rows: [
          { name: 'Pink Lady', color: 'light red' },
          { name: 'Granny Smith', color: 'green' },
          { name: 'Red Delicious', color: 'red' }
        ]
      }
    ]

    const filteredTableMutative = tableData.map(data => {
      const filteredColumns = data.rows.filter(row => {
        return row.color.includes('red')
      })

      data.rows = filteredColumns

      return data
    })

    const assert =
      JSON.stringify(tableData) === JSON.stringify(filteredTableMutative)
    const handleClick = () => window.alert(assert)

    return <button onClick={handleClick}>Click to run</button>
  }

  const tableData = [
    {
      title: 'Apples',
      rows: [
        { name: 'Pink Lady', color: 'light red' },
        { name: 'Granny Smith', color: 'green' },
        { name: 'Red Delicious', color: 'red' }
      ]
    }
  ]

  const filteredTable = tableData.map(data => {
    const filteredColumns = data.rows.filter(row => {
      return row.color.includes('red')
    })

    return {
      ...data,
      rows: filteredColumns
    }
  })

  const assert = JSON.stringify(tableData) === JSON.stringify(filteredTable)
  const handleClick2 = () =>
    window.alert(assert + '\n\n' + JSON.stringify(tableData, null, 2))

  return <button onClick={handleClick2}>Click to run</button>
}

FilterDemo.propTypes = {
  filterType: propTypes.string
}

export default FilterDemo
