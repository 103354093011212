import { ClubsIcon, DiamondsIcon, HeartsIcon, SpadesIcon } from './Icons'

export const suiteIcons = {
  club: ClubsIcon,
  diamond: DiamondsIcon,
  heart: HeartsIcon,
  spade: SpadesIcon
}

export const getSuitWithIfElse = suit => {
  if (suit === 'club') {
    return ClubsIcon
  } else if (suit === 'diamond') {
    return DiamondsIcon
  } else if (suit === 'heart') {
    return HeartsIcon
  } else if (suit === 'spade') {
    return SpadesIcon
  } else {
    return null
  }
}

export const getSuiteWithIf = suit => {
  if (suit === 'club') return ClubsIcon
  if (suit === 'diamond') return DiamondsIcon
  if (suit === 'heart') return HeartsIcon
  if (suit === 'spade') return SpadesIcon

  return null
}

export const getSuiteWithTernary = suit => {
  return suit === 'club'
    ? ClubsIcon
    : suit === 'diamond'
    ? DiamondsIcon
    : suit === 'heart'
    ? HeartsIcon
    : suit === 'spade'
    ? SpadesIcon
    : null
}

export const getSuitWithCase = suit => {
  switch (suit) {
    case 'club':
      return ClubsIcon
    case 'diamond':
      return DiamondsIcon
    case 'heart':
      return HeartsIcon
    case 'spade':
      return SpadesIcon
    default:
      return null
  }
}
