import React from 'react'

const Nc = props => {
  return (
    <svg
      viewBox="0 0 100 56"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M90.655-.038a9.376 9.376 0 00-8.186 4.819l-.19.392-7.294 14.468-9.28 18.287 6.08 11.981.333.655a9.517 9.517 0 004.057 3.843 9.581 9.581 0 004.057-3.843l.334-.655 18.215-35.943.44-.869A9.342 9.342 0 00100 9.361C100 4.238 95.79.02 90.667.01l-.012-.048zM34.354 17.369L28.286 5.471l-.333-.654A9.39 9.39 0 0023.908.985a9.424 9.424 0 00-4.045 3.82l-.345.666-18.24 35.908-.44.856a9.349 9.349 0 00-.813 3.813c0 5.13 4.222 9.352 9.352 9.352a9.364 9.364 0 008.19-4.836l.202-.381 7.341-14.468 9.268-18.275-.024-.071z"
        fill="#ff5100"
        fillRule="nonzero"
      />
      <path
        d="M90.631-.038a9.363 9.363 0 00-8.174 4.819l-.202.392-7.341 14.468-9.292 18.287 6.092 11.981.333.655a9.45 9.45 0 004.057 3.843 9.455 9.455 0 004.057-3.843l.345-.655 18.275-35.943.428-.869a9.342 9.342 0 00.779-3.736c0-5.123-4.21-9.341-9.333-9.351l-.024-.048z"
        fill="url(#prefix___Linear1)"
        fillRule="nonzero"
      />
      <path
        d="M9.381 55.382a9.363 9.363 0 008.174-4.759l.202-.392 7.353-14.468 9.28-18.287-6.08-12.041-.333-.654A9.512 9.512 0 0023.908.938a9.517 9.517 0 00-4.057 3.843l-.333.654L1.219 41.379l-.44.868A9.345 9.345 0 000 45.983c0 5.123 4.21 9.342 9.333 9.352l.048.047z"
        fill="url(#prefix___Linear2)"
        fillRule="nonzero"
      />
      <path
        d="M34.354 17.369L28.286 5.471l-.333-.654A9.438 9.438 0 0023.896.974a9.84 9.84 0 011.773-.667A9.692 9.692 0 0128.048.01h12.707a9.431 9.431 0 018.162 4.759l.333.654 16.431 32.553 6.056 11.898.333.654a9.527 9.527 0 004.105 3.879 9.572 9.572 0 01-4.128.963H59.268a9.421 9.421 0 01-8.15-4.759l-.345-.654-16.419-32.588z"
        fill="#ff8c44"
        fillRule="nonzero"
      />
      <defs>
        <linearGradient
          id="prefix___Linear1"
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(51.95183) rotate(-79.474 1.347 -.403)"
        >
          <stop offset={0} stopColor="#d4202c" />
          <stop offset={0.1} stopColor="#dc3d29" stopOpacity={0.79} />
          <stop offset={0.2} stopColor="#e45926" stopOpacity={0.58} />
          <stop offset={0.32} stopColor="#ea7123" stopOpacity={0.4} />
          <stop offset={0.43} stopColor="#f08521" stopOpacity={0.25} />
          <stop offset={0.55} stopColor="#f4941f" stopOpacity={0.14} />
          <stop offset={0.68} stopColor="#f79f1e" stopOpacity={0.06} />
          <stop offset={0.82} stopColor="#f8a51d" stopOpacity={0.02} />
          <stop offset={1} stopColor="#f9a71d" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix___Linear2"
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(100.54 9.58 11.078) scale(51.95432)"
        >
          <stop offset={0} stopColor="#d4202c" />
          <stop offset={0.1} stopColor="#dc3d29" stopOpacity={0.79} />
          <stop offset={0.2} stopColor="#e45926" stopOpacity={0.58} />
          <stop offset={0.32} stopColor="#ea7123" stopOpacity={0.4} />
          <stop offset={0.43} stopColor="#f08521" stopOpacity={0.25} />
          <stop offset={0.55} stopColor="#f4941f" stopOpacity={0.14} />
          <stop offset={0.68} stopColor="#f79f1e" stopOpacity={0.06} />
          <stop offset={0.82} stopColor="#f8a51d" stopOpacity={0.02} />
          <stop offset={1} stopColor="#f9a71d" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Nc
