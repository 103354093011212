import React from 'react'
import reactStringReplace from 'react-string-replace'
import Input from '@components/Input'
import useWindowSize from '../../../src/hooks/useWindowSize'
import { Caption, Code, Form, Table, Th, Wrap } from './styles'
import tableData from './tableData'

const isCommand = /^\$|:/

const DataTable = () => {
  const [searchResults, setSearchResults] = React.useState(tableData)
  const [searchValue, setSearchValue] = React.useState('')
  const fieldRef = React.useRef(null)
  const { width } = useWindowSize()

  const doSearch = React.useCallback(
    value => {
      setSearchValue(value)

      if (value) {
        const filteredTableData = tableData.map(table => {
          const items = table.items
            // Do the actual search
            .filter(item => {
              // This regex has to be defined here and not earlier
              const reg = new RegExp(value, 'gi')

              return (
                reg.test(item.description) || reg.test(item.command.toString())
              )
            })
            .map(item => {
              // Highlight the search results by wrapping them to `<mark>`
              const description = reactStringReplace(
                item.description,
                value,
                (match, index) => <mark key={index}>{match}</mark>
              )

              return { ...item, description }
            })

          // Create a copy of the shape or you’ll edit the original.
          // https://stackoverflow.com/a/35922654
          return { ...table, items }
        })

        const hasResults = filteredTableData.some(data => data.items.length > 0)

        setSearchResults(hasResults || value ? filteredTableData : tableData)
      } else {
        setSearchResults(tableData)
      }
    },
    [setSearchResults]
  )

  React.useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search)
      const searchTerm = params.get('s')
      doSearch(searchTerm)
      fieldRef.current.value = searchTerm
    }
  }, [doSearch])

  const handleChange = event => {
    doSearch(event.target.value)
  }

  // This updates the URL of the page when enter is hit in the search form
  const handleSubmit = event => {
    event.preventDefault()
    const url = new URL(window.location.href)
    url.searchParams.set('s', searchValue)
    window.location.href = url.href
  }
  const hasResults = searchResults.some(rows => rows.items.length > 0)

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          type="search"
          placeholder="Search"
          aria-label="search"
          onChange={handleChange}
          ref={fieldRef}
        />
      </Form>
      <Wrap>
        {!hasResults
          ? 'No results'
          : searchResults.map(rows => {
              const hasRows = rows.items.length > 0

              return (
                <Table key={rows.name}>
                  <Caption>{rows.name}</Caption>
                  <thead>
                    <tr>
                      <Th>Command</Th>
                      <Th>Description</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {!hasRows ? (
                      <tr>
                        <td>No results</td>
                      </tr>
                    ) : (
                      rows.items.map((item, index) => (
                        <tr key={index}>
                          <React.Fragment key={item.command.toString()}>
                            <td>
                              {item.command.map((command, index, commands) => {
                                const isLast = index + 1 === commands.length
                                // This is used to check if the line should wrap or
                                // not, long lines can cause the table to be too
                                // wide.
                                const shouldWrap =
                                  command.length > 25 && width > 950
                                const separator = ', '

                                return (
                                  <React.Fragment key={command}>
                                    {
                                      // Is it a command, does it start with $ or :.
                                      isCommand.test(command) ? (
                                        <Code shouldWrap={shouldWrap}>
                                          {command}
                                        </Code>
                                      ) : (
                                        <kbd>{command}</kbd>
                                      )
                                    }
                                    {!isLast && separator}
                                  </React.Fragment>
                                )
                              })}
                            </td>
                            <td>{item.description}</td>
                          </React.Fragment>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )
            })}
      </Wrap>
    </>
  )
}

export default DataTable
