import React from 'react'
import PropTypes from 'prop-types'
import VisuallyHidden from '@components/VisuallyHidden'
import things from './things'
import { BlankButton } from './styles'

const sortTableData = (array, { sortBy, direction }) => {
  return array.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return direction === 'ascending' ? -1 : 1
    if (a[sortBy] > b[sortBy]) return direction === 'ascending' ? 1 : -1
    return 0
  })
}

const SortButton = ({ direction, id, onClick, sortBy }) => {
  const arrows = { ascending: '↓', descending: '↑' }
  const arrow = sortBy === id ? arrows[direction] : '↕︎'

  return (
    <BlankButton id={id} onClick={onClick}>
      {arrow}
      <VisuallyHidden>Sort {direction}</VisuallyHidden>
    </BlankButton>
  )
}

SortButton.propTypes = {
  direction: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  sortBy: PropTypes.string
}

const SortableTable = ({ sortConfig }) => {
  const [sortedItems, setSortedItems] = React.useState(things)
  const [direction, setDirection] = React.useState()
  const [sortBy, setSortBy] = React.useState()
  const { showSortUi } = sortConfig || {}

  const handleClick = event => {
    const sortDir = direction === 'descending' ? 'ascending' : 'descending'
    setDirection(sortDir)
    setSortBy(event.target.id)
    const sortConfig = { sortBy: event.target.id, direction: sortDir }
    setSortedItems(sortTableData(things, sortConfig))
  }

  return (
    <table>
      <caption align="bottom">List of random things</caption>
      <thead>
        <tr>
          <th>
            Name{' '}
            {showSortUi && (
              <SortButton
                direction={direction}
                id="name"
                onClick={handleClick}
                sortBy={sortBy}
              />
            )}
          </th>
          <th>
            Description{' '}
            {showSortUi && (
              <SortButton
                direction={direction}
                id="description"
                onClick={handleClick}
                sortBy={sortBy}
              />
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedItems.map(thing => (
          <tr key={thing.name}>
            <td>{thing.name}</td>
            <td>{thing.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

SortableTable.propTypes = {
  sortConfig: PropTypes.shape({
    sortBy: PropTypes.string,
    direction: PropTypes.oneOf(['ascending', 'descending'])
  })
}

export default SortableTable
