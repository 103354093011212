import styled from 'styled-components'

export const StickerWrap = styled.div({
  fontFamily: 'Georgia',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #aaa',
  height: '400px',
  marginBottom: '20px'
})

export const Header = styled.div(props => ({
  backgroundColor: 'Bisque',
  fontSize: '150%',
  padding: '15px',
  position: props.isSticky ? 'sticky' : undefined,
  top: 0
}))

export const Footer = styled.div(props => ({
  position: props.isSticky ? 'sticky' : undefined,
  bottom: props.isSticky ? 0 : undefined,
  fontFamily: 'Helvetica',
  backgroundColor: 'LightGray',
  padding: '10px 15px'
}))

export const Content = styled.div({
  flex: 1,
  margin: '20px'
})
