const tableData = [
  {
    name: 'Sessions',
    items: [
      {
        command: ['$ tmux', '$ tmux new', '$ tmux new-session', ': new'],
        description: 'Start a new session'
      },
      {
        command: ['$ tmux new -s testSes', ': new -s testSes'],
        description: 'Start a new named session'
      },
      {
        command: [
          '$ tmux kill-ses -t testSes',
          '$ tmux kill-session -t testSes'
        ],
        description: 'Kill session testSes'
      },
      {
        command: ['$ tmux kill-session -a'],
        description: 'Kill all sessions but the current'
      },
      {
        command: ['$ tmux kill-session -a -t testSes'],
        description: 'Kill all sessions but testSes'
      },
      {
        command: ['Ctrl+b $'],
        description: 'Rename the session'
      },
      {
        command: ['Ctrl+b d'],
        description: 'Detach from session'
      },
      {
        command: [': attach -d'],
        description: 'Detach others clients on the session'
      },
      {
        command: ['$ tmux -ls', '$ tmux list-sessions', 'Ctrl+b s'],
        description: 'Show all sessions'
      },
      {
        command: [
          '$ tmux a',
          '$ tmux at',
          '$ tmux attach',
          '$ tmux attach-session'
        ],
        description: 'Attach to last session'
      },
      {
        command: [
          '$ tmux a -t testSes',
          '$ tmux at -t testSes',
          '$ tmux attach -t testSes',
          '$ tmux attach-session -t testSes'
        ],
        description: 'Attach to a session called testSes'
      },
      {
        command: ['Ctrl+b ('],
        description: 'Move to the previous session'
      },
      {
        command: ['Ctrl+b )'],
        description: 'Move to the next session'
      }
    ]
  },
  {
    name: 'Windows',
    items: [
      {
        command: ['$ tmux new -s testSes -n testWin'],
        description: 'Start a new session called testSes and window testWin'
      },
      {
        command: ['Ctrl+b c'],
        description: 'New window'
      },
      {
        command: ['Ctrl+b ,'],
        description: 'Rename the current window'
      },
      {
        command: ['Ctrl+b &'],
        description: 'Close the current window'
      },
      {
        command: ['Ctrl+b p'],
        description: 'Go to the previous window'
      },
      {
        command: ['Ctrl+b n'],
        description: 'Go to the next window'
      },
      {
        command: ['Ctrl+b 0 ... 9'],
        description: 'Switch/select window by number'
      },
      {
        command: [': swap-window -s 2 -t 1'],
        description:
          'Reorder windows, swap window number 2 (source) and 1 (destination)'
      },
      {
        command: [': swap-window -t -1'],
        description: 'Move the current window to left by one'
      }
    ]
  },
  {
    name: 'Panes',
    items: [
      {
        command: ['Ctrl+b ;'],
        description: 'Toggle last active pane'
      },
      {
        command: ['Ctrl+b %'],
        description: 'Split pane vertically'
      },
      {
        command: ['Ctrl+b "'],
        description: 'Split pane horizontally'
      },
      {
        command: ['Ctrl+b {'],
        description: 'Move the current pane left'
      },
      {
        command: ['Ctrl+b }'],
        description: 'Move the current pane right'
      },
      {
        command: ['Ctrl+b ➡︎', 'Ctr➡l+b ⬅︎', 'Ctrl+b ⬆︎', 'Ctrl+b ⬇︎'],
        description: 'Switch to pane to the direction'
      },
      {
        command: [': setw synchronize-panes'],
        description: 'Toggle synchronize-panes (sends a command to all panes)'
      },
      {
        command: ['Ctrl+b Space'],
        description: 'Toggle between pane layouts'
      },
      {
        command: ['Ctrl+b o'],
        description: 'Switch to next pane'
      },
      {
        command: ['Ctrl+b q'],
        description: 'Show pane numbers'
      },
      {
        command: ['Ctrl+b b q 0 ... 9'],
        description: 'Switch/select pane by number'
      },
      {
        command: ['Ctrl+b z'],
        description: 'Toggle pane zoom'
      },
      {
        command: ['Ctrl+b !'],
        description: 'Convert pane into a window'
      },
      {
        command: ['Ctrl+b ⬆︎', 'Ctrl+b Ctrl+⬆︎', 'Ctrl+b+⬇︎', 'Ctrl+b Ctrl+⬇︎'],
        description:
          'Resize current pane height (holding second key is optional)'
      },
      {
        command: ['Ctrl+b ➡︎', 'Ctrl+b Ctrl+➡︎', 'Ctrl+b+⬅︎', 'Ctrl+b Ctrl+⬅︎'],
        description:
          'Resize current pane width (holding second key is optional)'
      },
      {
        command: ['Ctrl+b x'],
        description: 'Close current pane'
      }
    ]
  },
  {
    name: 'Copy mode',
    items: [
      {
        command: [': setw -g mode-keys vi'],
        description: 'Use vi keys in buffer'
      },
      {
        command: ['Ctrl+b ['],
        description: 'Enter the copy mode'
      },
      {
        command: ['Ctrl+b PgUp'],
        description: 'Enter copy mode and scroll one page up'
      },
      {
        command: ['q'],
        description: 'Quit copy mode'
      },
      {
        command: ['g'],
        description: 'Go to top line'
      },
      {
        command: ['G'],
        description: 'Go to bottom line'
      },
      {
        command: ['⬆︎'],
        description: 'Scroll up'
      },
      {
        command: ['⬇︎'],
        description: 'Scroll down'
      },
      {
        command: ['h'],
        description: 'Move cursor left'
      },
      {
        command: ['j'],
        description: 'Move cursor down'
      },
      {
        command: ['k'],
        description: 'Move cursor up'
      },
      {
        command: ['l'],
        description: 'Move cursor right'
      },
      {
        command: ['w'],
        description: 'Move cursor forward one word'
      },
      {
        command: ['b'],
        description: 'Move cursor backward one word'
      },
      {
        command: ['/'],
        description: 'Search forward'
      },
      {
        command: ['?'],
        description: 'Search backwards'
      },
      {
        command: ['n'],
        description: 'Next keyword occurrence'
      },
      {
        command: ['N'],
        description: 'Previous keyword occurrence'
      },
      {
        command: ['Space'],
        description: 'Start selection'
      },
      {
        command: ['Esc'],
        description: 'Clear selection'
      },
      {
        command: ['Enter'],
        description: 'Copy selection '
      },
      {
        command: ['Ctrl+b ]'],
        description: 'Paste contents of buffer_0'
      },
      {
        command: [': show-buffer'],
        description: 'display buffer_0 contents'
      },
      {
        command: [': capture-pane'],
        description: 'Copy entire visible contents of pane into a buffer'
      },
      {
        command: [': list-buffers'],
        description: 'Show all buffers'
      },
      {
        command: [': choose-buffer'],
        description: 'Show all buffers and paste selected'
      },
      {
        command: [': save-buffer buffer.txt'],
        description: 'Save buffer contents to buffer.txt'
      },
      {
        command: [': delete-buffer -b 1'],
        description: 'Delete buffer_1'
      }
    ]
  },
  {
    name: 'Misc.',
    items: [
      {
        command: ['Ctrl+b :'],
        description: 'Enter the command mode'
      },
      {
        command: [': set -g OPTION'],
        description: 'Set OPTION for all sessions'
      },
      {
        command: [': setw -g OPTION'],
        description: 'Set OPTION for all windows'
      }
    ]
  },
  {
    name: 'Help',
    items: [
      {
        command: ['$ tmux info'],
        description: 'Show every session, window, pane, and so on.'
      },
      {
        command: ['Ctrl+b ?'],
        description: 'Show shortcuts'
      }
    ]
  }
]

export default tableData
