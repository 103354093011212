const things = [
  { name: 'Sock', description: 'Clothing item' },
  { name: 'Tube', description: 'Topological shape' },
  { name: 'Chair', description: 'Sitting device' },
  { name: 'Aluminum siding', description: 'Building material' },
  { name: 'Cleanex', description: 'Tissue' },
  { name: 'Jade buddha', description: 'Trinket' },
  { name: 'Knob', description: 'Adjective' },
  { name: 'Tangerine', description: 'Fruit' }
]

export default things
