import React from 'react'
import PropTypes from 'prop-types'
import things from './things'

const sortTableData = (array, { sortBy, direction }) => {
  return array.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return direction === 'ascending' ? -1 : 1
    if (a[sortBy] > b[sortBy]) return direction === 'ascending' ? 1 : -1
    return 0
  })
}

const SortableTableSimple = ({ sortConfig }) => {
  const shouldSort = sortConfig?.sortBy
  const tableData = shouldSort ? sortTableData(things, sortConfig) : things

  return (
    <table>
      <caption align="bottom">List of random things</caption>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(thing => (
          <tr key={thing.name}>
            <td>{thing.name}</td>
            <td>{thing.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

SortableTableSimple.propTypes = {
  sortConfig: PropTypes.shape({
    sortBy: PropTypes.string,
    direction: PropTypes.string
  })
}

export default SortableTableSimple
