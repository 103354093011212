import styled from 'styled-components'
import { visuallyHidden } from '../../../src/utils/style-utils'

const gapWidth = '20px'
const CUSTOM_LARGE_SCREEN = '@media (min-width: 950px)'

export const Wrap = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: gapWidth
})

export const Code = styled.code(({ shouldWrap }) => ({
  whiteSpace: shouldWrap ? 'initial' : 'nowrap'
}))

export const Table = styled.table(props => ({
  fontSize: 'var(--font-medium)',
  flex: `0 0 calc(100% - ${gapWidth})`,
  opacity: props.isDim ? 0.3 : 1,

  [CUSTOM_LARGE_SCREEN]: {
    flex: `0 0 calc(50% - ${gapWidth})`
  }
}))

export const Caption = styled.caption({
  backgroundImage: 'linear-gradient(90deg, var(--yellow-light), var(--white))',
  borderLeft: '1px solid var(--gray-light)',
  position: 'relative',
  left: '-1px',
  fontWeight: 'bold',
  padding: '5px var(--spacing-l)',
  textAlign: 'left'
})

export const Col = styled.div({
  marginBottom: 'var(--spacing-l)',
  maxWidth: '750px'
})

export const Form = styled.form({
  marginBottom: 'var(--spacing-l)'
})

export const Th = styled.th(visuallyHidden)
