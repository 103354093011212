import Tabs1 from "../../../../contents/posts/tabs/Tabs1";
import Tabs2 from "../../../../contents/posts/tabs/Tabs2";
import Tabs3 from "../../../../contents/posts/tabs/Tabs3";
import Tabs4 from "../../../../contents/posts/tabs/Tabs4";
import * as React from 'react';
export default {
  Tabs1,
  Tabs2,
  Tabs3,
  Tabs4,
  React
};