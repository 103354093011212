import styled from 'styled-components'

export const Handle = styled.div(props => ({
  borderBottom: '0 solid transparent',
  borderRight: '15px solid black',
  borderTop: '15px solid transparent',
  bottom: 0,
  cursor: props.cursor,
  display: 'inline-block',
  height: 0,
  position: 'absolute',
  right: 0,
  width: 0
}))

export const Resizable = styled.div({
  backgroundColor: 'Pink',
  marginBottom: '20px',
  padding: '20px',
  position: 'relative',
  transitionDuration: '100ms',
  transitionProperty: 'height, width'
})
