import styled from 'styled-components'

export const DemoText = styled.div(({ background, shadow }) => ({
  backgroundColor: background,
  fontSize: '5em',
  fontWeight: 'bold',
  lineHeight: 0.76,
  marginBottom: '40px',
  padding: background && '15px 15px',
  textShadow: shadow
}))
