import alfredSettingsImg from "../../../../contents/posts/tmux-cheat-sheet/alfred-custom-tmux-search-settings.png";
import alfredSearchImg from "../../../../contents/posts/tmux-cheat-sheet/alfred-tmux-search.png";
import DataTable from "../../../../contents/posts/tmux-cheat-sheet/TmuxTable";
import { Col, Wrap } from "../../../../contents/posts/tmux-cheat-sheet/styles";
import * as React from 'react';
export default {
  alfredSettingsImg,
  alfredSearchImg,
  DataTable,
  Col,
  Wrap,
  React
};