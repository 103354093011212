import React from 'react'
import PropTypes from 'prop-types'
import { useResize } from './useResize'
import { Handle, Resizable } from './styles'

const ResizableComponent = props => {
  const ref = React.useRef()
  const { initResize, size, cursor } = useResize(ref, props.options)

  return (
    <Resizable ref={ref}>
      {props.children(size)}
      <Handle cursor={cursor} onMouseDown={initResize} />
    </Resizable>
  )
}

ResizableComponent.propTypes = {
  options: PropTypes.shape({
    step: PropTypes.number,
    axis: PropTypes.oneOf(['horizontal', 'vertical', 'both'])
  }),
  children: PropTypes.func.isRequired
}

export default ResizableComponent
