/* eslint-disable react/prop-types */
import React from 'react'
import { ListItem } from './BasicList'

const List = props => {
  const children = React.Children.map(props.children, (child, index) => {
    return React.cloneElement(child, { index })
  })

  return <ul>{children}</ul>
}

const CompoundList = () => {
  return (
    <List>
      <ListItem>foo</ListItem>
      <ListItem>bar</ListItem>
      <ListItem>baz</ListItem>
    </List>
  )
}

export default CompoundList
