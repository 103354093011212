import React from 'react'
import styled from 'styled-components'

const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getData = () => {
  return Array.from({ length: 6 }, () => getRandomInt(10, 200))
}

const Item = styled.div(props => ({
  alignItems: 'flex-end',
  backgroundColor: 'var(--green)',
  height: props.height + 'px',
  transitionDuration: '200ms',
  transitionProperty: 'height',
  width: 'calc(100% / 6)'
}))

const Wrap = styled.div({
  alignItems: 'flex-end',
  backgroundColor: '#ddd',
  display: 'flex',
  height: '200px',
  marginBottom: '10px'
})

const Box = styled.div({
  marginBottom: '20px'
})

// const Refreshometer = styled.span({
//   fontFamily: 'var(--mono-space)',
//   marginLeft: '20px'
// })

// const useRefresh = () => {
//   const [refresh, setRefresh] = React.useState(0)

//   return () => setRefresh(refresh + 1)
// }

const Graph = () => {
  const [refresh, setRefresh] = React.useState(0)
  // const refresh = useRefresh()

  return (
    <Box>
      <Wrap>
        {getData().map(amount => (
          <Item height={amount} key={amount} />
        ))}
      </Wrap>
      <button onClick={() => setRefresh(refresh + 1)}>Refresh data</button>
    </Box>
  )
}

export default Graph
