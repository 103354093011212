import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import Icon from '@components/Icon'

const rotate = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' }
})

const SpinnerIcon = styled(Icon)(
  { marginRight: '5px' },
  css`
    animation: ${rotate} 1s linear infinite;
  `
)

const Button = styled.button(props => ({
  opacity: props.isLoading ? 0.5 : undefined,
  padding: '5px 10px',
  pointerEvents: props.isLoading ? 'none' : undefined
}))

const LoadingButton = () => {
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClick = () => {
    setIsLoading(true)
  }

  return (
    <Button
      aria-disabled={isLoading}
      isLoading={isLoading}
      onClick={handleClick}
    >
      {isLoading && (
        <SpinnerIcon
          aria-live="assertive"
          aria-role="alert"
          icon="spinner"
          isBlock={false}
        />
      )}
      Submit
    </Button>
  )
}

export default LoadingButton
