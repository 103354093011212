import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min + 1) + min)
}

const getRandomIntRound = (min, max) => {
  min = Math.round(min)
  max = Math.round(max)

  return Math.round(Math.random() * (max - min + 1) + min)
}

const getOccurrences = array =>
  array.reduce((acc, randomNumber) => {
    acc[randomNumber] = ++acc[randomNumber] || 1
    return acc
  }, {})

const getRandomData = (isSorted, randomMethod) => {
  // Generate these inside this function so the refresh works.
  const randomNumbers = Array.from({ length: 10000 }, () =>
    randomMethod === 'round'
      ? getRandomIntRound(0, 1000)
      : getRandomIntInclusive(0, 1000)
  )
  const occurrences = getOccurrences(randomNumbers)

  const arr = Object.keys(occurrences).map(x => occurrences[x])

  return isSorted
    ? arr.sort((a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
      })
    : arr
}

const Bar = styled.div(props => ({
  alignItems: 'flex-end',
  backgroundColor: 'Gray',
  height: props.elementHeight * 10 + 'px',
  width: '0.1%'
}))

const Wrap = styled.div({
  border: '1px solid var(--gray)',
  marginBottom: '20px',
  padding: '10px'
})

const Container = styled.div({
  alignItems: 'flex-end',
  display: 'flex',
  height: '240px',
  marginBottom: '10px',
  width: '100%'
})

const Button = styled.button({
  marginRight: '10px'
})

const Chart = ({ shouldSort }) => {
  const [isSorted, setIsSorted] = React.useState(shouldSort)
  const [remountCount, setRemountCount] = React.useState(0)
  const [randomMethod, setRandomMethod] = React.useState('normal')
  const refresh = () => setRemountCount(remountCount + 1)

  const handleSort = () => {
    setIsSorted(prev => !prev)
  }

  const handleChange = event => {
    setRandomMethod(event.target.value)
  }

  return (
    <Wrap>
      <Container>
        {getRandomData(isSorted, randomMethod).map((key, index) => (
          <Bar key={index} elementHeight={key} />
        ))}
      </Container>
      <Button onClick={refresh}>Refresh</Button>
      <Button onClick={handleSort}>{isSorted ? 'Unsort' : 'Sort'}</Button>
      <select onChange={handleChange}>
        <option value="ceil-floor">With ceil/floor</option>
        <option value="round">With round</option>
      </select>
    </Wrap>
  )
}

Chart.propTypes = {
  shouldSort: PropTypes.bool
}

export default Chart
