import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TabList = styled.ul({
  display: 'flex',
  gap: '1px',
  listStyleType: 'none',
  margin: '0 0 1px'
})

const TabItem = styled.li({
  flex: '1 0 auto',
  margin: 0
})

// 1. Stretch the link to fill its parent
const Tab = styled.a({
  backgroundColor: 'var(--gray-light)',
  display: 'flex',
  flexGrow: 1 /* 1 */,
  padding: '10px 15px',
  textTransform: 'uppercase'
})

const TabPanel = styled.div({
  backgroundColor: 'var(--gray-light)',
  marginBottom: '1px',
  minHeight: '100px',
  padding: '15px'
})

const Tabs = ({ tabs = [], id }) => {
  const getId = (...rest) => [...rest, id].join('-')

  return (
    <div>
      <TabList>
        {tabs.map(tab => (
          <TabItem key={tab}>
            <Tab href={'#' + getId(tab, 'tabpanel')}>{tab}</Tab>
          </TabItem>
        ))}
      </TabList>
      {tabs.map(tab => (
        <TabPanel id={getId(tab, 'tabpanel')} key={tab}>
          {tab} tab’s content
        </TabPanel>
      ))}
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired
}

export default Tabs
