import React from 'react'

const Do = props => (
  <svg rx="15%" viewBox="0 0 512 512" {...props}>
    <path
      fill="#0080ff"
      d="M78 373v-47h47v104h57V300h74v147A191 191 0 1065 256h74a117 117 0 11117 117"
    />
  </svg>
)

export default Do
